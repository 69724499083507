<template>
  <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340" >
    <foreignObject x='106' y='106' width='128px' height='128px'>
      <img
          width='128px'
          height='128px'
          :src="avatarImagePath"
          style="border-radius: 50%"
      />
    </foreignObject>
    <circle cx="170" cy="170" r="160" stroke="#A02838"/>
    <circle cx="170" cy="170" r="135" stroke="#BF7527"/>
    <circle cx="170" cy="170" r="110" stroke="#C48D27"/>
    <circle cx="170" cy="170" r="85" stroke="#C4B427"/>
    <circle cx="170" cy="170" r="65" stroke="#D8D225"/>
  </svg>
</template>

<script>
import PlayerMixin from "../mixins/PlayerMixin";

export default {
  name: "AvatarProgressCircle",
  mixins:[PlayerMixin]
}
</script>

<style scoped lang="scss">
.loader {
  max-width: 15rem;
  width: 100%;
  height: auto;
  stroke-linecap: round;
}
circle {
  fill: none;
  stroke-width: 3.5;
  animation-name: preloader;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transform-origin: 170px 170px;
  will-change: transform;

  &:nth-of-type(1) {
    stroke-dasharray: 550px;
  }

  &:nth-of-type(2) {
    stroke-dasharray: 500px;
  }

  &:nth-of-type(3) {
    stroke-dasharray: 450px;
  }

  &:nth-of-type(4) {
    stroke-dasharray: 300px;
  }

  &:nth-of-type(5) {
    stroke-dasharray: 200px;
  }

  @for $i from 1 through 4 {
    &:nth-of-type(#{$i}) {
      animation-delay: -#{$i * 0.15}s;
    }
  }
}

@keyframes preloader {
  50% {
    transform: rotate(360deg);
  }
}
</style>