<template>
  <v-card flat class="pg-color-strong-orange mb-10" dark>
    <v-card-title class="text-center">
      <h5 style="width: 100%">Félicitations ! Vous avez terminé ce scénario !</h5>
    </v-card-title>
    <v-card-text>
      <v-row align="center" justify="center" no-gutters v-if="currentGame.game.pointsReward > 0">
        <v-col align-self="center" cols="6" class="text-right">
          <h1 class="full-title white--text" style="font-size: xxx-large">
            {{ currentGame.game.pointsReward }}
          </h1></v-col>
        <v-col>
          <jumping-pimentos x-x-l/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn class="pg-hot-gradient mt-16" large max-width="200px" width="100%" @click="setGameInPlayWinner()">
        <pg-icon x-x-l>i-play</pg-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PgIcon from "../../PGIcon";
import CurrentGameInPlayMixin from "../../../mixins/CurrentGameInPlayMixin";
import JumpingPimentos from "../../JumpingPimentos";

export default {
  name: "WinnerScenario",
  components: {JumpingPimentos, PgIcon},
  mixins: [CurrentGameInPlayMixin]
}
</script>

<style scoped>

</style>