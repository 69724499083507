<template>
  <v-card class="pg-color-white fill-height ma-auto mb-10 overflow-y-scroll" max-width="1080px"
          :min-width="$vuetify.breakpoint.mobile?'100%':'80%'" flat max-height="100vh">
    <v-card-title class="wave" width="100%">
      <v-row align="center" align-content="center" justify="center">
        <h5 class="text-center white--text" v-if="gameInfo">{{ gameInfo.title }}</h5>
        <span class="caption white--text ml-4" v-if="!isUnlimitedTime && gameStarted"> ({{ hoursLeft }})</span>
      </v-row>
    </v-card-title>
    <div id="wave"></div>
    <v-card-text class="pg-scroll-bar text-center text-content" v-if="!hasAnsweredInvitation">
      <div v-html="replaceCodesForText(currentStageInstructions?currentStageInstructions.text:'')" class="mb-2"
           :style="(countDown||isUnlimitedTime)?'':'filter:blur(3px)'"/>
      <answer-game-in-play-invitation class="mt-2" v-if="currentPlayerInGame && !gameStarted"/>
      <winner-selection v-if="gameStarted && (countDown||isUnlimitedTime) "/>
      <message-interaction-writer
          v-if="gameStarted && (countDown||isUnlimitedTime) && currentStage && (currentStage.gameStagePlayerInteractionsFrom.length || currentStage.gameStagePlayerInteractionsDestination.length)"/>
      <message-interaction-reader/>
      <game-action-buttons/>
    </v-card-text>
    <v-row v-else justify="center" align="center" class="fill-height">
      <v-progress-circular :size="120"
                           :width="3"
                           indeterminate
                           color="purple" class="ma-auto"/>
    </v-row>
  </v-card>
</template>

<script>
import CurrentGameInPlayMixin from "../../../mixins/CurrentGameInPlayMixin";
import AnswerGameInPlayInvitation from "../AnswerGameInPlayInvitation";
import WinnerSelection from "../WinnerSelection";
import GameActionButtons from "../GameActionButtons";
import MessageInteractionWriter from "../interaction/MessageInteractionWriter";
import MessageInteractionReader from "../interaction/MessageInteractionReader";
import PopUpMixin from "../../../mixins/PopUpMixin";
import {current_game} from "../../../store/modules/current_game.module";

export default {
  name: "StorylineGame",
  components: {
    MessageInteractionReader,
    MessageInteractionWriter, GameActionButtons, WinnerSelection, AnswerGameInPlayInvitation
  },
  mixins: [CurrentGameInPlayMixin, PopUpMixin],
  data() {
    return {
      countDown: 0,
      timeoutId: null,
      isWaitingForPopUp: false,
      popUpId: -1,
    }
  },
  computed: {
    isUnlimitedTime() {
      return (this.currentGame && this.currentGame.game) ? this.currentGame.game.duration < 0 || this.extendDurationPointCost <= 0 : false;
    },
    hoursLeft() {
      return new Date(this.countDown * 1000).toISOString().substr(11, 8)
    },
    maxTimeToFinish() {
      return this.currentGame && current_game.game ? this.currentGame.game.duration : 7200;
    },
    extendDurationPointCost() {
      return Math.ceil(this.currentGame.game.pointsReward / 2);
    }
  },
  mounted() {
    if (this.currentPlayerInGame && this.currentGame && this.currentGame.currentStage !== undefined) {
      this.getStageInstructions({currentGame: this.currentGame, playerNumber: this.currentPlayerInGame.number});
    }
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        if (this.popUpId >= 0) {
          this.removePopUp(this.popUpId);
          this.popUpId = -1;
        }
        this.isWaitingForPopUp = false;
        this.timeoutId = setTimeout(() => {
          this.setCountDown();
          this.countDownTimer()
        }, 1000);
      } else {
        clearTimeout(this.timeoutId);
        if (!this.isWaitingForPopUp && !this.isUnlimitedTime) {
          this.isWaitingForPopUp = true;
          this.showPopUp({
            title: "Reprendre :" + this.currentGame.game.title,
            message: 'Le délai est dépassé ! Voulez-vous payer un coût en pimentos de ' + this.extendDurationPointCost + ' pour continuer ?',
            buttonTitle: 'Payer ' + this.extendDurationPointCost,
            buttonCancel: 'Quitter la partie',
            persistent: true,
            callback: () => {
              this.extendGameDuration();
            },
            callbackCancel: () => {
              this.stopGame();
            }
          }).then(popUpId => {
            this.popUpId = popUpId
          })
        }
      }
    },
    setCountDown() {
      if (!this.currentGame || !this.currentGame.startedAt) {
        this.countDown = this.maxTimeToFinish;
        return false;
      }
      let timeCreated = Date.parse(this.currentGame.startedAt);
      let now = Date.now();
      this.countDown = (this.maxTimeToFinish - Math.round((now - timeCreated) / 1000));
      this.countDown = this.countDown < 0 ? 0 : this.countDown;
      if (!timeCreated) {
        return false;
      }
      return true;
    },

    async setTimeLeft() {
      return new Promise((resolve, reject) => {
        if (!this.currentGame) {
          if (this.timeoutId) {
            clearTimeout(this.timeoutId)
          }
          return reject(false);
        }
        if (this.setCountDown()) {
          resolve(this.countDown);
        } else {
          reject(false);
        }
      })
    }
  },
  watch: {
    'currentStage': function () {
      if (this.currentPlayerInGame && this.currentGame) {
        this.getStageInstructions({currentGame: this.currentGame, playerNumber: this.currentPlayerInGame.number});
      }
    },
    'currentGame': function () {
      this.setTimeLeft().then(() => {
        this.countDownTimer();
      }).catch(e => {
        console.error(e);
      });
    }
  },
  created() {
    this.setTimeLeft().then(() => {
      this.countDownTimer()
    }).catch(e => {
      console.error(e);
    })
  },
  destroyed() {
    console.log("Storyline destroyed")
    clearTimeout(this.timeoutId);
  }
}
</script>

<style scoped lang="scss">
.text-content {
  line-height: 1.105rem !important;
  text-align: justify !important;
}

.wave {
  position: relative;
  height: 50px;
  width: 100%;
  background: linear-gradient(135deg, #54000E, #811F31, #A02838, #A7462F, #B8642A, #BF7527, #C48D27, #C4B427, #D8D225);
  border-radius: 47% 44% 0% 18%;
}
</style>