<template>
  <div v-if="gameStarted && isFinalStage">
    <winner-defi v-if="currentGame.game.gameType.code === 'defi'"/>
    <winner-scenario v-if="currentGame.game.gameType.code === 'scenarios'"/>
  </div>
</template>

<script>
import CurrentGameInPlayMixin from "../../mixins/CurrentGameInPlayMixin";
import WinnerDefi from "./win-by-game-type/WinnerDefi";
import WinnerScenario from "./win-by-game-type/WinnerScenario";
export default {
  name: "WinnerSelection",
  components: {WinnerScenario, WinnerDefi},
  mixins: [CurrentGameInPlayMixin]
}
</script>

<style scoped>

</style>