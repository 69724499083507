<template>
  <div>
    <v-card v-for="(interaction,index) in currentStagePlayerInteractionsFrom" v-bind:key="index"
            class="pg-color-strong-orange-transparent mb-12">
      <v-card-title class="pg-hot-gradient white--text">{{ interaction.labelFrom }}
        <v-spacer/>
        <pg-icon>i-chat-white</pg-icon>
        <span
            v-if="interaction.playerNumberDestination">{{
            replaceCodesForText('[PLAYER_' + interaction.playerNumberDestination + ']')
          }}</span>
      </v-card-title>
      <template v-if="currentGameInPlayStagePlayerInteractionsFrom[index]">
      <quill-editor class="white mb-16" v-model="currentGameInPlayStagePlayerInteractionsFrom[index].message" :options="editorOptions"/>
      <label class="subtitle-2 ma-0" v-show="currentGameInPlayStagePlayerInteractionsFrom[index].message.length">Prévisualisation: </label>
      <v-card-text v-html="currentGameInPlayStagePlayerInteractionsFrom[index].message" class="mb-12"/>
      </template>
    </v-card>
  </div>
</template>

<script>
import QuillEditorMixin from "../../../mixins/QuillEditorMixin";
import CurrentGameInPlayMixin from "../../../mixins/CurrentGameInPlayMixin";
import PgIcon from "../../PGIcon";

export default {
  name: "MessageInteractionWriter",
  components: {PgIcon},
  mixins: [QuillEditorMixin, CurrentGameInPlayMixin],
  mounted() {
    this.loadInteractions();
  },
  watch: {
    'currentStage': function () {
      console.log('changement de stage')
      this.loadInteractions();
    }
  },
  methods: {
    loadInteractions() {
      if (this.currentPlayerInGame && this.currentGame) {
        this.getStagePlayerInteractionFrom({
          currentGame: this.currentGame,
          playerNumber: this.currentPlayerInGame.number
        })
      }
    }
  }
}
</script>

<style scoped>

</style>