<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
        transition="dialog-bottom-transition"
    >
      <v-card v-if="winner">
        <v-card-title class="pg-hot-gradient white--text subtitle-1 justify-center"
                      v-if="currentGame.game.pointsReward>0">
          Décerner <span class="pimentos-text ml-2"> {{ currentGame.game.pointsReward }}</span>
          <jumping-pimentos/>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <v-img max-height="200px" contain
                 src="~@/assets/victory.svg">
            <v-avatar size="128" style="margin-top: 27px">
              <v-img :src="getPlayerAvatarImagePath(winner.player)"></v-img>
            </v-avatar>
          </v-img>
          à <strong>{{ winner.player.nickName }}</strong> ?
        </v-card-text>
        <v-card-actions>
          <v-btn
              dark
              class="pg-hot-gradient-reversed"
              @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-spacer/>
          <v-btn
              dark
              class="pg-hot-gradient "
              @click="setGameInPlayWinner(winner)"
          >
            <strong><span class="glow">Valider</span></strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card flat>
      <v-card-title>
        <h5>Pour terminer le défi, désignez le vainqueur :</h5>
      </v-card-title>
      <v-card-text>
        <v-row align="center" align-content="center" justify="center">
          <v-col cols="6" class="text-center" v-for="(playerIngame,index)  in playersInGame" v-bind:key="index">
            <v-btn large width="100%" height="100%" class=""
                   @click="confirmWinnerSelection(playerIngame)" icon>
              <v-avatar size="128">
                <v-img :src="getPlayerAvatarImagePath(playerIngame.player)" contain v-if="index==0"/>
                <v-img :src="getPlayerAvatarImagePath(playerIngame.player)" contain v-else/>
              </v-avatar>
            </v-btn>
            {{ playerIngame.player.nickName }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CurrentGameInPlayMixin from "../../../mixins/CurrentGameInPlayMixin";
import JumpingPimentos from "../../JumpingPimentos";
import PlayerMixin from "../../../mixins/PlayerMixin";

export default {
  name: "WinnerDefi",
  components: {JumpingPimentos},
  mixins: [CurrentGameInPlayMixin, PlayerMixin],
  data: function () {
    return {
      dialog: false,
      winner: null,
    }
  },
  methods: {
    confirmWinnerSelection(playerInGame) {
      this.winner = playerInGame;
      this.dialog = true;
    }
  }
}
</script>

<style scoped>

</style>