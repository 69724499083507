<template>
  <div>
    <v-card v-for="(interaction,index) in currentGameInPlayStagePlayerInteractionsDestination" v-bind:key="index"
            class="pg-color-strong-orange-transparent mb-12">
      <v-card-title class="pg-hot-gradient white--text">{{ interaction.gameStagePlayerInteraction.labelDestination }}
        <v-spacer/>
        <pg-icon>i-chat-white</pg-icon>
        <span
            v-if="interaction.gameStagePlayerInteraction.playerNumberFrom">{{
            replaceCodesForText('[PLAYER_' + interaction.gameStagePlayerInteraction.playerNumberFrom + ']')
          }}</span>
      </v-card-title>
      <v-card-text v-html="currentGameInPlayStagePlayerInteractionsDestination[index].message"/>
    </v-card>
  </div>
</template>

<script>
import CurrentGameInPlayMixin from "../../../mixins/CurrentGameInPlayMixin";
import PgIcon from "../../PGIcon";

export default {
  name: "MessageInteractionReader",
  components: {PgIcon},
  mixins: [CurrentGameInPlayMixin],
  created() {
    this.loadInteractions();
  },
  methods: {
    loadInteractions() {
      console.log('on charge les intéractions reçues')
      if (this.currentPlayerInGame && this.currentPlayerInGame.player && this.currentGame) {
        this.getGameInPlayStagePlayerInteractionDestination({
          currentGame: this.currentGame,
          playerNumber: this.currentPlayerInGame.player.id
        })
      }
    }
  },
  watch: {
    'currentStage': function () {
      this.loadInteractions();
    }
  }
}
</script>

<style scoped>

</style>