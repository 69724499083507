<template>
  <v-row align-content="center" justify="end">
    <v-col cols="12" offset-sm="1" sm="10" align-self="end" class="text-right" v-if="currentStageActions.length>0">
      <v-btn v-for="action in currentStageActions" v-bind:key="action.id" dark class="pg-color-strong-orange mr-3"
             @click="goToStage(action.goToStage)">
        {{ action.label }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import CurrentGameInPlayMixin from "../../mixins/CurrentGameInPlayMixin";

export default {
  name: "GameActionButtons",
  mixins: [CurrentGameInPlayMixin],
  watch: {
    'currentStage': function () {
      if (this.currentPlayerInGame && this.currentGame) {
        this.getStageActions({currentGame: this.currentGame, playerNumber: this.currentPlayerInGame.number});
      }
    }
  }
}
</script>

<style scoped>

</style>