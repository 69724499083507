<template>
  <div v-if="currentPlayerInGame">
    <v-row v-if="!hasAcceptedInvitation" align="center" justify="center" no-gutters class="pb-5">
      <v-col cols="6" class="text-center">
        <v-btn @click="refuse" dark class="pg-color-dark-moderate-red" small :disabled="hasMadeAChoice">Refuser</v-btn>
      </v-col>
      <v-col cols="6" class="text-center">
        <v-btn @click="setResponseToGameInvitation(true)" dark class="pg-color-strong-orange" small :disabled="hasMadeAChoice">Rejoindre</v-btn>
      </v-col>
    </v-row>
    <v-row v-else-if="!gameStarted" align="center" justify="center" align-content="center" style="min-height: 50vh"
           class="text-center">
      <v-col cols="12"><b>En attente d'acceptation d'autres joueurs.</b></v-col>
      <v-col cols="12" align-self="center">
        <avatar-progress-circle class="mt-2"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CurrentGameInPlayMixin from "../../mixins/CurrentGameInPlayMixin";
import AvatarProgressCircle from "../AvatarProgressCircle";
import PopUpMixin from "../../mixins/PopUpMixin";

export default {
  name: "AnswerGameInPlayInvitation",
  components: {AvatarProgressCircle},
  mixins: [CurrentGameInPlayMixin, PopUpMixin],
  data() {
    return {
      hasAcceptedInvitation: false,
      hasMadeAChoice:false,
    }
  },
  methods: {
    refuse() {
      this.hasMadeAChoice=true;
      this.showPopUp({
        title: "Êtes-vous sûr(e) de vouloir refuser cette partie ?",
        message: "Vous ne gagnerez pas de points, et votre partie ne sera pas sauvegardée.\n" +
            "<br>Ce serait dommage, on va bien s'amuser...",
        buttonTitle: "Refuser",
        hidePimentos : true,
        callback: () => {
          this.setResponseToGameInvitation(false);
        },
        callbackCancel: function () {
          this.hasMadeAChoice = false;
        }.bind(this),
        persistent:true
      })
    },
    setResponseToGameInvitation(answer) {
      this.hasMadeAChoice=true;
      let playerInGame = {
        '@id': this.currentPlayerInGame['@id'],
        hasAcceptedInvitation: answer
      };
      this.updateGameInPlayPlayer(playerInGame).then(() => {
            if (!answer) {
              this.$router.push({name: 'Home'});
            } else {
              this.hasAcceptedInvitation = answer;
              this.$forceUpdate();
            }
          }
      );
    },
  },
  created() {
    this.hasAcceptedInvitation = this.currentPlayerInGame ? this.currentPlayerInGame.hasAcceptedInvitation : false;
  },
  watch: {
    'currentPlayerInGame': function () {
      this.hasAcceptedInvitation = this.currentPlayerInGame ? this.currentPlayerInGame.hasAcceptedInvitation : false;
    }
  }
}
</script>

<style scoped>

</style>